import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 0;
  z-index: 3100;
`;

interface ModalMaskProps {
  showModal: boolean;
  animationTime: string;
}

export const ModalMask = styled.div<ModalMaskProps>`
  top: 0;
  right: 0;
  z-index: 250;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  background: ${props => (props.showModal ? props.theme.modal.overlay : 'none')};
  animation-name: ${props =>
    props.showModal ? 'animate-background-show' : 'animate-background-hide'};

  animation-duration: 0.4s;

  @keyframes animate-background-show {
    from {
      background: none;
    }
    to {
      background: ${props => props.theme.modal.overlay};
    }
  }

  @keyframes animate-background-hide {
    from {
      background: ${props => props.theme.modal.overlay};
    }
    to {
      background: none;
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  justify-content: center;
  border-radius: 10px;
  align-content: center;
  padding: 2px 0;
`;

export const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const ApplyButton = styled.span`
  color: #04be02;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex: 1;
  text-align: center;
  padding: 10px;
  border-right: 1px solid grey;
  font-weight: bold;
  cursor: pointer;
`;

export const CancelButton = styled.span`
  color: #808281;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex: 1;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
`;

interface ModalBodyProps {
  showModal: boolean;
  animationTime: string;
}

export const ModalBody = styled.div<ModalBodyProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  z-index: 300;
  background-color: #25060e;
  opacity: ${props => (props.showModal ? 1 : 0)};
  animation-name: ${props => (props.showModal ? 'animate-modal-show' : 'animate-modal-hide')};
  animation-duration: ${props => props.animationTime};

  @keyframes animate-modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes animate-modal-hide {
    from {
      opacity: 1;
      width: 300px;
    }
    to {
      opacity: 0;
      width: 320px;
    }
  }
`;

export const ModalDescription = styled.div`
  padding: 14px 15px 14px 15px;
  text-align: center;
  line-height: 1.5;
`;

export const ModalFooter = styled.div`
  display: flex;
  border-top: 1px solid grey;
`;
