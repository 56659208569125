import { takeLatest, fork, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18n from '../i18n';
import API from '../api';
import { user, actionCreators } from '../actions/user';
import storage from '../helpers/storage';

function* registration({ phone_number, license_number, referrer_code }) {
  const formData = new FormData();
  formData.append('data[phone_number]', phone_number);

  formData.append('data[license_number]', license_number);
  formData.append('data[referrer_code]', referrer_code);

  const response = yield call(API.auth.registration, formData);

  switch (response.code) {
    case 200: {
      const { auth_key, user_id, user_photo } = response;

      if (auth_key && user_id) {
        //@ts-ignore
        window.location = 'https://skeddy.net/apps/';
        yield put(actionCreators.setUser({ user_id, auth_key, user_photo }));
        return;
      }

      yield put(actionCreators.registration.success());
      break;
    }
    case 422: {
      if (response.errors) {
        yield put(actionCreators.registration.failure(response.errors));
        break;
      }
      toast.error(response.message, {
        autoClose: false,
      });
      yield put(actionCreators.registration.failure({ errors: {} }));
      break;
    }
    default: {
      yield put(actionCreators.registration.failure({ errors: {} }));
      // toast.error(messages.serverError, { type: 'default' });
      break;
    }
  }
}

function* registrationConfirmSms({
  phone_number,
  license_number,
  verification_code,
  referrer_code,
}) {
  const formDataWithSms = new URLSearchParams();

  formDataWithSms.append('data[phone_number]', phone_number);
  formDataWithSms.append('data[license_number]', license_number);
  formDataWithSms.append('data[referrer_code]', referrer_code);
  formDataWithSms.append('data[verification_code]', verification_code);

  const response = yield call(API.auth.registration, formDataWithSms);

  switch (response.code) {
    case 201:
    case 200: {
      if (response.verification_code_need && response.verification_code_need === true) {
        const errors = { verification_code: i18n.t('registration.validationCodeRequired') };
        yield put(actionCreators.registrationConfirmSms.failure(errors));
        return;
      } else {
        //@ts-ignore
        window.location = 'https://skeddy.net/apps/';
      }
      const { auth_key, user_id, user_photo } = response;

      yield put(actionCreators.registrationConfirmSms.success());
      yield put(actionCreators.setUser({ user_id, auth_key, user_photo }));

      return;
    }
    case 422: {
      const errors = response.message
        ? {
            verification_code: response.message,
          }
        : response.errors;
      yield put(actionCreators.registrationConfirmSms.failure(errors));
      break;
    }
    default: {
      yield put(actionCreators.registrationConfirmSms.failure({ errors: {} }));
      // toast.error(messages.serverError, { type: 'default' });
      break;
    }
  }
}

function* handleLogout() {
  yield call(storage.clear);
}

export function* watchRegistration() {
  yield takeLatest(user.REGISTRATION.REQUEST, registration);
}

export function* watchRegistrationConfirmSms() {
  yield takeLatest(user.REGISTRATION_CONFIRM_SMS.REQUEST, registrationConfirmSms);
}

export function* watchUserLogout() {
  yield takeLatest(user.LOGOUT, handleLogout);
}

export default function* flow() {
  yield fork(watchRegistration);
  yield fork(watchUserLogout);
  yield fork(watchRegistrationConfirmSms);
}
