import axios from 'axios';

import { baseUrl } from '../config/urls';
import { addHeaders, apiCallDecorator } from './helpers';

const api = axios.create({
  timeout: 60000,
});

api.defaults.baseURL = baseUrl;

const auth = {
  registration: (formData: FormData) => api.post('/auth', formData),
};

const settingsUrl = '/filter';
const settings = {
  get: () => api.get(settingsUrl),
  set: (formData, isNew) =>
    isNew ? api.post(settingsUrl, formData) : api.post(settingsUrl, formData),
};

const apiList = [auth];

apiList.forEach(apiSection => {
  Object.keys(apiSection).forEach(method => {
    apiSection[method] = apiCallDecorator(apiSection[method]);
  });
});

api.interceptors.request.use(addHeaders);

export default {
  api,
  auth,
  settings,
};
