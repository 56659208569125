import styled from 'styled-components';

interface ContainerProps {
  showLogo: boolean;
  style?: object;
}

export const Container = styled.div<ContainerProps>`
  overflow: auto;

  padding: 0;
  position: relative;
`;
