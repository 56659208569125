import { AxiosRequestConfig } from 'axios';
import storage, { StorageKeys } from '../helpers/storage';
import i18n from '../i18n';

export function apiCallDecorator(apiCall) {
  return async function(...args) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      const response = await apiCall.call(this, ...args);
      return response.data.data;
    } catch (e) {
      if (!e.response) {
        return { code: 502 };
      }
      return e.response.data.data;
    }
  };
}

export async function addHeaders(config: AxiosRequestConfig) {
  config.baseURL = storage.getItem(StorageKeys.apiUrl) || config.baseURL;
  config.headers['X-Language'] = `${i18n.language}-US`;

  return config;
}
