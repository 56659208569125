import { LOGOUT, SET_USER } from '../actions/user';
import { AuthReducerState } from '../store/interfaces';

const initialState: Readonly<AuthReducerState> = {
  user: null,
};

function authReducer(state: AuthReducerState = initialState, action): AuthReducerState {
  switch (action.type) {
    case SET_USER: {
      const { user } = action;

      if (!user) {
        return {
          ...initialState,
        };
      }

      return {
        ...initialState,
        user,
      };
    }

    case LOGOUT: {
      return {
        ...state,
        user: null,
      };
    }

    default:
      return state;
  }
}

export default authReducer;
