import React from 'react';
import styled from 'styled-components';

export const InfoTextStyled = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: #de308d;
  font-size: 25px;
  line-height: 26px;
  font-weight: 400;
`;

export const AgreementStyled = React.memo(styled.div`
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.7em;
  background: ${props => props.theme.input.background};
  max-height: 200px;
  overflow-y: auto;
  color: #000000;

  p {
    margin: 3px 0;
    text-align: left !important;
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 300;
  }

  @media (max-height: 800px) {
    padding: 12px;
    height: 220px;
    overflow: scroll;
  }

  @media (max-height: 700px) {
    height: 200px;
  }

  @media (max-height: 600px) {
    height: 180px;
  }
`);

export const PhoneInputContainer = styled.div`
  position: relative;
  margin: 20px 0 10px;

  input {
    padding: 8px 32px;
  }
`;

export const PhoneInputHelper = styled.span`
  position: absolute;
  font-size: 18px;
  z-index: 1;
  top: 6px;
  left: 10px;
  color: #000000;
`;
