export const REQUEST: 'REQUEST' = 'REQUEST';
export const SUCCESS: 'SUCCESS' = 'SUCCESS';
export const FAILURE: 'FAILURE' = 'FAILURE';
export const CLEAR: 'CLEAR' = 'CLEAR';

export function createRequestTypes(base: string, root = ''): any {
  return [REQUEST, SUCCESS, FAILURE, CLEAR].reduce((acc, type) => {
    acc[type] = `APP/${root}${base}_${type}`;
    return acc;
  }, {});
}

export const action = (type: string, payload: {} = {}) => ({
  type,
  ...payload,
});
