import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import {
  ModalHeader,
  ModalMask,
  ModalTitle,
  ApplyButton,
  ModalContainer,
  ModalBody,
  ModalDescription,
  CancelButton,
  ModalFooter,
} from './styled';

interface ModalProps {
  onClose(): void;
  title?: any;
  children?: any;
  onApply(): void;
  applyText?: string;
  cancelText?: string;
  showCancel?: boolean;
}

const animationTime = '0.3s';

const modalRoot = document.getElementById('modal-root');

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  showCancel = true,
  onClose,
  onApply,
  applyText,
  cancelText,
}: ModalProps) => {
  const { t } = useTranslation();
  const [isClosing, setIsClosing] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  const closeModal = useCallback(() => {
    if (isClosing) {
      return null;
    }

    setIsClosing(true);

    setTimeout(() => {
      onClose();
    }, parseFloat(animationTime) * 1000);
  }, [onClose]);

  return ReactDOM.createPortal(
    <ModalContainer>
      <ModalMask animationTime={animationTime} showModal={!isClosing} onClick={closeModal} />
      <ModalBody animationTime={animationTime} showModal={!isClosing}>
        {title && (
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
        )}
        {children && (
          <ModalDescription style={title ? { paddingTop: 0 } : {}}>{children}</ModalDescription>
        )}
        <ModalFooter>
          <ApplyButton onClick={onApply}>{applyText || t('components.modal.apply')}</ApplyButton>
          {showCancel && (
            <CancelButton onClick={closeModal}>
              {cancelText || t('components.modal.cancel')}
            </CancelButton>
          )}
        </ModalFooter>
      </ModalBody>
    </ModalContainer>,
    modalRoot!
  );
};

export default Modal;
