import { action, createRequestTypes, REQUEST, SUCCESS, FAILURE, CLEAR } from '../helpers/actions';

const ROOT = 'USER/';

export const SET_USER = `APP/${ROOT}SET_USER`;
export const LOGOUT = `APP/${ROOT}LOGOUT`;
export const CLEAN_VERIFICATION_CODE = `APP/${ROOT}LOGOUT`;

export const REGISTRATION = createRequestTypes('REGISTRATION', ROOT);
export const REGISTRATION_CONFIRM_SMS = createRequestTypes('REGISTRATION_CONFIRM_SMS', ROOT);

export const registration = {
  request: user => action(REGISTRATION[REQUEST], user),
  success: () => action(REGISTRATION[SUCCESS]),
  failure: errors => action(REGISTRATION[FAILURE], { errors }),
  clear: () => action(REGISTRATION[CLEAR]),
};

export const registrationConfirmSms = {
  request: user => action(REGISTRATION_CONFIRM_SMS[REQUEST], user),
  success: () => action(REGISTRATION_CONFIRM_SMS[SUCCESS]),
  failure: errors => action(REGISTRATION_CONFIRM_SMS[FAILURE], { errors }),
  clear: () => action(REGISTRATION_CONFIRM_SMS[CLEAR]),
};

export const setUser = user => action(SET_USER, { user });
export const logout = () => action(LOGOUT);
export const cleanVerificationCode = () => action(CLEAN_VERIFICATION_CODE);

export const user = {
  REGISTRATION,
  REGISTRATION_CONFIRM_SMS,
  SET_USER,
  LOGOUT,
  CLEAN_VERIFICATION_CODE,
} as const;

export const actionCreators = {
  setUser,
  registration,
  registrationConfirmSms,
  logout,
  cleanVerificationCode,
} as const;
