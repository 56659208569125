import styled, { css } from 'styled-components';
import React from 'react';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 480px;

  display: flex;
  flex-direction: column;
  padding: 15px 0;
  justify-content: center;

  h1 {
    font-weight: normal;
    text-align: center;
    margin: 30px 0 22px 0;
    font-size: 52px;
  }

  h2 {
    font-weight: normal;
    text-align: center;
    margin: 0 0 25px 0;
    color: ${props => props.theme.altText};
  }

  h3 {
    text-align: center;
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 15px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  ul li::before {
    content: '\\2022';
    color: #f7b72f;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

interface InputProps {
  error: string;
}

export const Input = styled.input<InputProps>`
  padding: 8px 10px;
  font-size: 18px;
  background: ${props => props.theme.input.background};
  color: #000000;
  outline: none;
  border: none;

  :focus {
    outline: none;
  }

  :focus ~ label {
    top: -6px;
    font-size: 13px;
  }

  ::placeholder {
    color: #686868;
  }

  ${({ value }) =>
    value !== undefined &&
    value !== '' &&
    value !== null &&
    css`
      ~ label {
        top: -6px;
        font-size: 13px;
      }
    `}

  ${({ error }) =>
    error &&
    `
      border-bottom: 2px solid ${props => props.theme.input.error};
      
      ~ label {
        color: ${props => props.theme.input.error};
      }
      
  `}
`;

export const InputError = styled.div`
  color: ${props => props.theme.input.error};
  margin-top: 5px;
  margin-left: 10px;
  font-size: 13px;
`;

interface ButtonStyledProps {
  isLoading?: boolean;
  isDisabled: boolean;
}

export const ButtonStyled = styled.span<ButtonStyledProps>`
  position: relative;
  color: #fff;
  font-size: 18px;
  box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.2);
  background: #de308d;
  border-radius: 40px;
  padding: 8px 30px 10px;
  display: inline-flex;
  align-items: center;
  user-select: none;
  ${({ isLoading }) => {
    if (isLoading) {
      return '';
    }

    return 'cursor: pointer;';
  }}

  transition: top 0.4s;

  &:active {
    top: 2px;
  }
`;

export const Loading = styled.span`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${({ theme }) => theme.background};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  margin-left: 8px;
  display: inline-block;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface ButtonProps {
  isLoading?: boolean;
  children: React.ReactNode;
  onClick?: any;
  style?: object;
  isDisabled?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  isLoading = false,
  children,
  onClick,
  style,
  isDisabled = false,
}) => (
  <ButtonStyled
    style={style}
    isDisabled={isDisabled}
    isLoading={isLoading}
    onClick={isDisabled ? null : onClick}
  >
    {children}
    {isLoading && <Loading />}
  </ButtonStyled>
);

export const Button = React.memo(ButtonComponent);

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

interface FormInputProps {
  value: string | number;
  type?: string;
  readonly?: boolean;
  placeholder?: string;
  name: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  error: string;
  labelText?: any;
  inputStyle?: object;
}

const FormInputComponent = ({
  value,
  name,
  placeholder = '',
  labelText = '',
  onChange,
  onPaste,
  error,
  type = 'text',
  readonly = false,
  inputStyle = {},
}: FormInputProps) => {
  if (type === 'checkbox') {
    return (
      <InputContainer>
        <label htmlFor={`${name}-checkbox`}>
          <div style={{ display: 'flex' }}>
            {readonly ? (
              <span>1234</span>
            ) : (
              <Input
                style={{ marginRight: 5, ...inputStyle }}
                id={`${name}-checkbox`}
                type={type}
                name={name}
                onPaste={onPaste}
                placeholder={placeholder}
                value={value}
                error={error}
                onChange={onChange}
              />
            )}
            {labelText}
          </div>
        </label>
        {error && <InputError>{error}</InputError>}
      </InputContainer>
    );
  }

  return (
    <InputContainer>
      {readonly ? (
        <div
          style={{
            padding: '8px 10px',
            fontSize: '18px',
            background: '#fbe4b2',
            color: '#000',
          }}
        >
          {value}
        </div>
      ) : (
        <Input
          style={inputStyle}
          type={type}
          name={name}
          value={value}
          error={error}
          onPaste={onPaste}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {error && <InputError>{error}</InputError>}
    </InputContainer>
  );
};

export const FormInput = React.memo(FormInputComponent);
