import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';

import { persistor, store } from '../../store';
import { GlobalStyle } from '../../common/styled/global';
import 'react-toastify/dist/ReactToastify.css';
import i18n, { allowedLanguages } from '../../i18n';
import { fixBrowserVH } from '../../common/fixBrowserVH';

import { ThemeProvider } from './ThemeProvider';
import DriverRegistration from '../driverRegistration';

const Index: React.FC = () => {
  const handleChangeBrowserLanguage = async () => {
    const language = window.navigator.language.slice(0, 2);

    if (allowedLanguages.indexOf(language) !== -1 && language !== i18n.language) {
      await i18n.changeLanguage(language);
    }
  };

  useEffect(() => {
    window.addEventListener('languagechange', handleChangeBrowserLanguage);

    fixBrowserVH();

    return () => {
      window.removeEventListener('languagechange', handleChangeBrowserLanguage);
    };
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <I18nextProvider i18n={i18n}>
            <GlobalStyle />
            <DriverRegistration />
            <ToastContainer hideProgressBar />
          </I18nextProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default Index;
