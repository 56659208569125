import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  html, body {
    height:100%;
  }
  
  #root {
    background: #25060E;
    color: #ffffff;
    font-family: "Sofia Pro", sans-serif;
    font-size: 17px;
    line-height: 1.4em;
  }
  
  a {
    text-decoration: none;
  }
`;
