const urls = {
  map: '/map',
  rides: '/rides',
  settings: '/settings',
  registration: '/registration',
  start: '/',
  login: '/login',
  setApiUrl: '/set-api-url',
  debug: '/debug',
  credits: '/credits',
  creditsHistory: '/credits-history',
  skeddy: 'https://skeddy.net',
} as const;

export const helpVideoLink = 'https://www.youtube.com/channel/UClwgc6rK1qSaZ-93Rya9dWQ';

export const zendeskLink = 'https://skeddy.zendesk.com/hc/en-us/requests/new';

export const fb = 'https://www.facebook.com/groups/skeddyappforlyftdrivers/';

export default urls;

export const allowedDebugLocations = ['localhost', 'signup2.skeddy.net'];

export const localApiUrl = 'https://api2.skeddy.net/api/client/v1';

export const apiUrl = 'https://api.skeddy.net/api/client/v1';

export const baseUrl =
  allowedDebugLocations.indexOf(window.location.hostname) === -1 ? apiUrl : localApiUrl;
