import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;

  th,
  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    color: #000;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td {
    font-size: 15px;
    font-weight: 300;
  }

  th {
    background: #f7b730;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
  }

  tr:nth-child(odd) td {
    background: #ffd16e;
  }

  tr:nth-child(even) td {
    background: #fdda8e;
  }
`;

interface Ride {
  driver: string;
  amount: string;
  state: string;
  rides: string;
}

export const BestRides = () => {
  const [rides, setRides] = useState<Ride[]>([]);

  useEffect(() => {
    fetch('https://api.skeddy.net/api/front/v1/drivers?direct-response=1&_format=json')
      .then(response => response.json())
      .then(data => {
        setRides(data.slice(0, 4) as Ride[]);
      });
  }, []);

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>Driver</th>
            <th>State</th>
            <th>Rides</th>
            <th>Earnings</th>
          </tr>
        </thead>
        <tbody>
          {rides.map((ride, index) => (
            <tr key={`${ride.driver}${index}`}>
              <td>{ride.driver}</td>
              <td>{ride.state}</td>
              <td>{ride.rides}</td>
              <td>{ride.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
