export enum StorageKeys {
  apiUrl = 'apiUrl',
  notificationAuthToken = 'notificationAuthToken',
  deviceToken = 'deviceToken',
  clientHeaders = 'clientHeaders',
  destinationTimes = 'destinationTimes',
  isMobileApp = 'isMobileApp',
  isSettingsSet = 'isSettingsSet',
  bestRides = 'bestRides',
}

const storage = {
  setItem(key: StorageKeys, value: string) {
    localStorage.setItem(key, value);
    window.dispatchEvent(new Event('storage'));
  },
  getItem(key: StorageKeys): string | null {
    return localStorage.getItem(key);
  },
  removeItem(key: StorageKeys) {
    localStorage.removeItem(key);
  },
  has(key: string) {
    return localStorage.getItem(key) !== null;
  },
  clear() {
    storage.removeItem(StorageKeys.notificationAuthToken);
    storage.removeItem(StorageKeys.isSettingsSet);
    storage.removeItem(StorageKeys.bestRides);
  },
} as const;

export default storage;
