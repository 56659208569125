import { CLEAN_VERIFICATION_CODE, REGISTRATION, REGISTRATION_CONFIRM_SMS } from '../actions/user';
import { CLEAR, FAILURE, REQUEST, SUCCESS } from '../helpers/actions';
import { UserFormsReducerState } from '../store/interfaces';

const initialState: Readonly<UserFormsReducerState> = {
  registration: {
    errors: {},
    isLoading: false,
    verificationCode: false,
  },
};

function userFormsReduces(
  state: UserFormsReducerState = initialState,
  action
): UserFormsReducerState {
  switch (action.type) {
    case REGISTRATION[REQUEST]: {
      const { registration } = state;

      return {
        ...state,
        registration: {
          ...registration,
          isLoading: true,
        },
      };
    }

    case REGISTRATION[SUCCESS]: {
      return {
        ...initialState,
        registration: {
          errors: {},
          isLoading: false,
          verificationCode: true,
        },
      };
    }

    case REGISTRATION_CONFIRM_SMS[SUCCESS]: {
      return {
        ...initialState,
        registration: {
          errors: {},
          isLoading: false,
          verificationCode: false,
        },
      };
    }

    case REGISTRATION_CONFIRM_SMS[FAILURE]:
    case REGISTRATION[FAILURE]: {
      const { registration } = state;
      const { errors } = action;

      return {
        ...state,
        registration: {
          ...registration,
          isLoading: false,
          errors: { ...errors },
        },
      };
    }

    case CLEAN_VERIFICATION_CODE: {
      const { registration } = state;

      return {
        ...state,
        registration: {
          ...registration,
          verificationCode: false,
          errors: {},
        },
      };
    }

    case REGISTRATION[CLEAR]: {
      return {
        ...state,
        registration: {
          errors: {},
          isLoading: false,
          verificationCode: false,
        },
      };
    }

    default:
      return state;
  }
}

export default userFormsReduces;
