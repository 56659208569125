import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import authReducer from 'reducers/auth';
import userFormsReducer from 'reducers/userForms';
import rootSaga from 'sagas';

import { history } from './history';
import { tokenMiddleware } from './middleware/token';

const sagaMiddleware = createSagaMiddleware();

const middleware = [tokenMiddleware, routerMiddleware(history), sagaMiddleware];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

export const rootReducer = combineReducers({
  auth: authReducer,
  userForms: userFormsReducer,
  router: connectRouter(history),
});

const composedEnhancers = compose(applyMiddleware(...middleware));

const devTools =
  process.env.NODE_ENV === 'production'
    ? composedEnhancers
    : composeWithDevTools(composedEnhancers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, devTools);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
