export const lightTheme = {
  name: 'light',

  bodyBackground: '#F7B731',
  background: '#F7B731',
  textColor: '#000',
  grid: {
    rowTitle: '#FCE3B2',
    evenRow: '#ffffff',
    oddRow: '#F2F2F2',
    secondColumn: '#696969',
  },

  rides: {
    money: '#C1362C',
    drop: {
      background: '#ff0000',
      color: '#fff',
    },
    free: '#C1362C',
  },

  filters: {
    value: '#696969',
  },

  footer: {
    link: '#252726',
    activeLink: '#838688',
  },

  sidebar: {
    background: 'rgb(252, 227, 178)',
    link: '#333333',
    creditsBackground: '#F7B731',
  },

  modal: {
    overlay: 'rgba(0, 0, 0, 0.6)',
    background: '#fbf9fe',
  },

  select: {
    headerTextColor: '#888',
    headerBackground: '#fff',
    background: '#fbf9fe',
  },

  tabs: {
    active: '#F7B731',
    activeText: '#000',
    default: '#fbd68d',
    defaultText: '#7F7F7F',
    secondBackground: '#fce3b2',
  },

  creditsHistory: {
    tableHeader: '#fce3b2',
    evenRow: '#ffffff',
    oddRow: '#F2F2F2',
  },

  buttonBackground: '#fff',

  status: {
    danger: '#FCAAD0',
    warning: '#FBD68D',
    success: '#C5E0B4',
  },

  map: {
    route: '#1500FF',
    background: '#fff',
    pin: {
      copy: {
        background: '#579927',
        color: '#fff',
      },
      copied: {
        background: '#fff',
        color: '#579927',
      },
      background: '#fff',
      color: '#000',
      time: '#579927',
      money: '#C1362C',
    },
  },

  input: {
    error: 'red',
    background: '#fbe4b2',
    placeholder: '#707176',
  },

  settings: {
    selected: 'rgb(0, 175, 80)',
    delete: '#C1362B',
  },

  inputError: 'red',

  inputBackground: '#fbe4b2',

  altText: '#fff',

  backgroundLogo: '/assets/icons/logo.svg',
};

export const darkTheme = {
  name: 'dark',

  bodyBackground: '#191919',
  background: '#191919',
  textColor: '#dcdcdc',

  grid: {
    rowTitle: '#202020',
    evenRow: '#404040',
    oddRow: '#595959',
    secondColumn: '#faff00',
  },

  rides: {
    money: '#faff00',
    drop: {
      background: '#ff0000',
      color: '#dcdcdc',
    },
    free: '#faff00',
  },

  filters: {
    value: '#d8d8d8',
  },

  footer: {
    link: '#808184',
    activeLink: '#FEFEFE',
  },

  sidebar: {
    background: '#202020',
    link: '#dcdcdc',
    creditsBackground: '#595959',
  },

  modal: {
    overlay: 'rgba(0, 0, 0, 0.8)',
    background: '#404040',
  },

  select: {
    headerTextColor: '#dcdcdc',
    headerBackground: '#595959',
    background: '#202020',
  },

  tabs: {
    active: '#191919',
    activeText: '#dcdcdc',
    default: '#404040',
    defaultText: '#808184',
    secondBackground: '#696969',
  },

  creditsHistory: {
    tableHeader: '#696969',
    evenRow: '#404040',
    oddRow: '#595959',
  },

  buttonBackground: '#C09001',

  status: {
    danger: '#ff60aa',
    warning: '#e0a800',
    success: '#569A28',
  },

  map: {
    route: '#faff00',
    background: '#191919',
    pin: {
      copy: {
        background: '#579927',
        color: '#dcdcdc',
      },
      copied: {
        background: '#dcdcdc',
        color: '#579927',
      },
      background: '#191919',
      color: '#dcdcdc',
      time: '#579927',
      money: '#C1362C',
    },
  },

  input: {
    error: 'red',
    background: '#808184',
    placeholder: '#202020',
  },

  settings: {
    selected: 'rgb(0 255 117)',
    delete: '#ff0000',
  },

  altText: '#C09001',

  backgroundLogo: '/assets/icons/logo_night.svg',
};
